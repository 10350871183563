import Button from 'components/v2/atomic/button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MFAResendButton({
  onClick
}: Readonly<{ onClick: () => void }>) {
  const [secondsLeftToResendCode, setSecondsLeftToResendCode] = useState(30);
  const { t } = useTranslation('authentication');

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      if (secondsLeftToResendCode <= 30) {
        setSecondsLeftToResendCode(secondsLeftToResendCode - 1);
      }
    }, 1000);

    if (secondsLeftToResendCode === 0) {
      clearInterval(countdownTimer);
    }

    return () => {
      clearInterval(countdownTimer);
    };
  }, [secondsLeftToResendCode]);

  return (
    <Button
      id="mfa-resend-code"
      variant="outline"
      color="blue"
      sxOverride={{ height: '3rem', width: '50%' }}
      onClick={() => {
        setSecondsLeftToResendCode(30);
        onClick();
      }}
      disabled={secondsLeftToResendCode !== 0}
    >
      {secondsLeftToResendCode !== 0
        ? `${t('MFA_RESEND_CODE_IN')} ${secondsLeftToResendCode}s`
        : t('MFA_SEND_CODE')}
    </Button>
  );
}
