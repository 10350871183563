import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_URLS } from 'utils/constants';
import useBreakpoint from 'utils/hooks/deprecated/useBreakpoint';
import styles from './SplashWrapper.module.scss';

interface SplashWrapperProps {
  children: React.ReactNode;
}

const SplashWrapper = ({ children }: SplashWrapperProps) => {
  const { t } = useTranslation('authentication');
  const [activeBreakpoint, breakpoints] = useBreakpoint();

  return (
    <div className={styles.splashWrapper}>
      <div className={styles.splashWrapperContent}>
        {children}

        <div className={styles.splashWrapperFooter}>
          <a
            href={EXTERNAL_URLS.OMERS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ReactSVG src="/images/logos/omers.svg" />
          </a>

          <div className={styles.loginFooterPhone}>
            <span>{t('SPLASH_WRAPPER_FOOTER_1')} </span>
            <span className="semiBold">{t('SPLASH_WRAPPER_FOOTER_2')} </span>
            <span>{t('SPLASH_WRAPPER_FOOTER_3')} </span>
            <span className="semiBold">{t('SPLASH_WRAPPER_FOOTER_4')}</span>
          </div>
        </div>
      </div>

      {breakpoints[activeBreakpoint] >= breakpoints.xlarge && (
        <>
          <div className={styles.background} />
          <div className={styles.backgroundTag}>
            {t('SPLASH_WRAPPER_IMAGE_TAG')}
          </div>
        </>
      )}
    </div>
  );
};

export default SplashWrapper;
