import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import MFACardNavButtons from 'containers/auth/LoginContainer/MFA/components/MFACustomButtons';
import { MFACardTypes } from 'containers/auth/LoginContainer/MFA/MFACards';

const MFAAccountLockedCard = ({
  setCurrentCard
}: {
  setCurrentCard: (state: MFACardTypes) => void;
}) => {
  const { t } = useTranslation('authentication');

  return (
    <MFACard id="mfa-account-locked" title={t('MFA_ACCOUNT_LOCKED')}>
      <div sx={{ display: 'flex', alignItems: 'start', gap: '1.5rem' }}>
        <Image
          src="/images/v2/smallIcons/locked.svg"
          alt=""
          width={92}
          height={92}
          sx={{
            width: 'unset',
            marginBottom: '2rem'
          }}
        />
        <p sx={{ mb: '0' }}>
          {t('MFA_YOU_ATTEMPTED_TO_INPUT_INCORRECT_PASSCODE')}
        </p>
      </div>
      <LinkButton
        id="mfa-reset-password"
        fill
        href="/reset-password"
        sx={{ m: '2rem 0 1.5rem' }}
      >
        {t('RESET_PASSWORD_HEADER')}
      </LinkButton>

      <MFACardNavButtons setCurrentCard={setCurrentCard} />
    </MFACard>
  );
};

export default MFAAccountLockedCard;
