import { TFunction } from 'i18next';
import { z } from 'zod';

const deviceProfileSchema = z
  .object({
    cpu: z.string().nullable(),
    timezone: z.string().nullable(),
    platform: z.string().nullable(),
    browser: z.string().nullable(),
    visitorId: z.string().nullable()
  })
  .optional();

export const loginFormSchema = (t: TFunction) =>
  z.object({
    username: z
      .string({ message: t('LOGIN_FIELD_USERNAME_ERROR_REQUIRED') })
      .min(1, { message: t('LOGIN_FIELD_USERNAME_ERROR_REQUIRED') })
      .trim(),
    password: z
      .string({ message: t('LOGIN_FIELD_PASSWORD_ERROR_REQUIRED') })
      .min(1, { message: t('LOGIN_FIELD_PASSWORD_ERROR_REQUIRED') })
      .trim(),
    deviceProfile: deviceProfileSchema
  });

export const verificationCodeSchema = (t: TFunction) =>
  z.object({
    verificationCode: z
      .string({ message: t('MFA_PLEASE_ENTER_6_DIGITS_CODE') })
      .min(6, { message: t('MFA_PLEASE_ENTER_6_DIGITS_CODE') })
  });

export const verifyCodeSchema = (t: TFunction) =>
  z
    .object({
      username: z.string(),
      verificationSid: z.string(),
      deviceProfile: deviceProfileSchema
    })
    .and(verificationCodeSchema(t));
