import { forwardRef } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import {
  Classes,
  FormGroup,
  FormGroupProps,
  HTMLInputProps
} from '@blueprintjs/core';
import classNames from 'classnames';
import { textEllipsisMixin } from 'utils/theme-ui/mixins';
import { formGroupStyle } from 'components/v2/common/formGroupStyle';
import InputMask from 'react-input-mask';
import Icon, { IconName } from '../icon/Icon';
import { IconNamesSmall } from '../icon/Icons';

export type FormGroupTextInputProps = Omit<
  FormGroupProps,
  'icon' | 'inline' | 'labelFor' | 'helperText' | 'intent' | 'large'
> &
  HTMLInputProps;
export interface TextInputProps extends FormGroupTextInputProps {
  labelInline?: boolean;
  error?: string;
  subText?: string;
  fill?: boolean;
  iconLeft?: IconName;
  iconRight?: IconName;
  hideErrorMessage?: boolean;
  inputMinWidth?: string;
  inputMaxWidth?: string;
  disableMarginBottom?: boolean;
  preventLabelWrap?: boolean;
  onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  mask?: string | Array<string | RegExp>;
  sxFormGroup?: ThemeUIStyleObject;
  onIconRightClick?: () => void;
  rightAlignLabelInfo?: boolean;
  id: string;
}

const inputGroupStyle = (
  fill: boolean,
  hasLeftIcon: boolean,
  hasRightIcon: boolean,
  hasError: boolean
): ThemeUIStyleObject => ({
  [`&.${Classes.INPUT_GROUP}`]: {
    maxWidth: fill ? '100%' : 'fit-content',
    [`> input.${Classes.INPUT}`]: {
      pl: hasLeftIcon ? '42px' : '15px',
      pr: hasRightIcon ? '42px' : '15px',
      background: 'grey10'
    },

    [`> .${Classes.INPUT}`]: {
      height: '48px',
      p: '12px 15px 12px 19px',
      fontSize: 'body',
      lineHeight: 'body',
      borderRadius: '5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: hasError ? 'extendedRed100' : 'grey100',
      boxShadow: 'none',
      '::placeholder': {
        color: 'grey80'
      },
      '&[aria-invalid="true"]': {
        background: 'extendedRed25'
      },
      ':hover:not(:read-only)': {
        boxShadow: 'none',
        backgroundColor: hasError ? 'extendedRed25' : 'extendedBlue25'
      },
      ':focus:not(:read-only)': {
        boxShadow: 'none',
        outlineColor: hasError ? 'extendedRed100' : 'extendedBlue25',
        backgroundColor: hasError ? 'extendedRed25' : 'extendedBlue25',
        outlineStyle: 'auto',
        outlineWidth: '2px'
      },
      '&:disabled, :read-only': {
        backgroundColor: 'grey10',
        borderColor: 'grey80',
        '&:hover': {
          backgroundColor: 'grey10'
        }
      }
    },
    '> .bp4-icon, > div > .bp4-icon': {
      m: '12px',
      svg: {
        width: '20px',
        height: '20px',
        color: 'black'
      }
    }
  }
});

/**
 * See: https://blueprintjs.com/docs/#core/components/text-inputs
 */
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      labelInfo,
      labelInline,
      error,
      subText,
      placeholder,
      fill = false,
      iconLeft,
      iconRight,
      inputMinWidth,
      inputMaxWidth,
      hideErrorMessage = false,
      disableMarginBottom = false,
      onClear,
      sxFormGroup,
      mask,
      id,
      onIconRightClick,
      rightAlignLabelInfo = false,
      ...props
    },
    ref
  ) => (
    <FormGroup
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
      inline={labelInline}
      helperText={
        ((!hideErrorMessage && !props.disabled && error) || subText) ??
        undefined
      }
      intent={error ? 'danger' : undefined}
      className={classNames(fill && Classes.FILL)}
      disabled={props.disabled}
      sx={{
        ...formGroupStyle(!!error, disableMarginBottom, rightAlignLabelInfo),
        ...sxFormGroup
      }}
    >
      <div
        className={Classes.INPUT_GROUP}
        sx={inputGroupStyle(fill, !!iconLeft, !!iconRight, !!error)}
      >
        {iconLeft && (
          <span className={Classes.ICON}>
            <Icon icon={iconLeft} />
          </span>
        )}
        <InputMask
          {...props}
          mask={mask || ''}
          inputRef={ref}
          type={props.type ?? 'text'}
          id={id}
          className={classNames(Classes.INPUT, {
            [Classes.FILL]: fill
          })}
          placeholder={placeholder}
          sx={{
            [`&.${Classes.INPUT}`]: {
              width: !fill ? 'auto' : undefined,
              minWidth: inputMinWidth,
              maxWidth: inputMaxWidth,
              ...textEllipsisMixin,
              display: 'block'
            }
          }}
          aria-invalid={!!error}
          disabled={props.disabled}
          maskPlaceholder={null}
        />
        <div
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {iconRight && (
            <span className={Classes.ICON}>
              <Icon icon={iconRight} onClick={onIconRightClick} />
            </span>
          )}
          {onClear && (
            <span className={Classes.ICON}>
              <Icon
                icon={IconNamesSmall.X_CIRCLE_SOLID}
                data-testid={`${id}-clear`}
                sx={{
                  pt: '2px',
                  color: 'grey100',
                  // Not setting this using the color prop as inherited styling from blueprint
                  // form group is incredibly specific and conflicts with anything set as color
                  // on the color prop
                  [`& svg`]: {
                    color: 'unset !important'
                  },
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={onClear}
              />
            </span>
          )}
        </div>
      </div>
    </FormGroup>
  )
);

export default TextInput;
