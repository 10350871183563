import Card from 'components/v2/atomic/card/Card';
import { format, isPast } from 'date-fns';
import { contentful } from 'modules/contentful';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { RobotoFlex } from 'styles/theme';
import clientSide from 'utils/logger/client-side';

type ContentfulResponse = {
  loading: boolean;
  error?: Error;
  value: {
    description: string;
    maintenanceWindows?: {
      fields: {
        maintenanceDate: string;
        maintenanceWindow: string;
      };
    }[];
  };
};

const baseFontStyling = {
  fontFamily: RobotoFlex.style.fontFamily
};

const MaintenancePeriodsTable = ({
  windows
}: {
  windows: ContentfulResponse['value']['maintenanceWindows'];
}) => (
  <ul
    sx={{
      listStyleType: 'none',
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }}
  >
    {windows?.map(window => (
      <li
        key={`${window.fields.maintenanceDate}-${window.fields.maintenanceWindow}`}
        sx={{
          display: 'grid',
          gridTemplateColumns: '200px max-content',
          width: '100%',

          span: {
            py: '0.5rem'
          },
          '&:not(:last-child)': {
            borderBottom: theme => `1px solid ${theme?.colors?.grey60}`
          }
        }}
      >
        <span sx={{ py: '0.5rem' }}>
          {format(new Date(window.fields.maintenanceDate), 'MMMM d, y')}
        </span>
        <span sx={{ fontWeight: 'semibold' }}>
          {window.fields.maintenanceWindow}
        </span>
      </li>
    ))}
  </ul>
);

export const SystemMaintenanceCard = ({ entryId }: { entryId: string }) => {
  const { t } = useTranslation('home');

  const contentfulResponse = useAsync(async () => {
    const response = await contentful.entry.get(entryId);
    return response.fields;
  }, []) as ContentfulResponse;
  // in this case the default ContentfulResponse is crazy generic and not helpful
  // without a significant amount of work, so we'll just cast it to the correct types

  if (contentfulResponse.error) {
    clientSide.error(
      'There was an error fetching maintenance periods from Contentful',
      contentfulResponse.error
    );
  }

  // Avoid showing the maintenance periods if there are none or if they are all in the past or there
  // is an error fetching them
  const validWindows =
    !contentfulResponse.loading &&
    !contentfulResponse.error &&
    contentfulResponse.value?.maintenanceWindows?.filter(
      window => !isPast(new Date(window.fields.maintenanceDate))
    );

  return !contentfulResponse.loading &&
    validWindows &&
    validWindows.length > 0 ? (
    <Card
      id="system_maintenance_card"
      accent
      accentColor="extendedYellow100"
      sxOverride={{ overflow: 'hidden', flex: 1 }}
    >
      <h2
        sx={{
          ...baseFontStyling,
          fontWeight: 'semibold',
          marginBottom: '1.5rem'
        }}
      >
        {t('SYSTEM_MAINTENANCE_TITLE')}
      </h2>
      <p sx={{ ...baseFontStyling, marginBottom: '1.5rem' }}>
        {contentfulResponse.value.description}
      </p>
      <div sx={{ height: '10rem' }}>
        <MaintenancePeriodsTable windows={validWindows} />
      </div>
    </Card>
  ) : null;
};
