import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RootState } from 'types';
import SplashWrapper from 'components/splashWrapper/SplashWrapper';
import Notification from 'components/notification/Notification';
import CommonNotification from 'containers/notifications/components/commonNotification/CommonNotification';
import Modal from 'components/modal/Modal';
import useModal from 'components/modal/useModal';
import { EXTERNAL_URLS } from 'utils/constants';
import useBrowserInfo from 'utils/hooks/useBrowserInfo';
import {
  loginAction,
  logoutAction,
  setPasswordExpiredFlagAction
} from 'containers/auth/userSessionActions';
import { featureFlagSelector } from 'utils';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import Banner from 'components/v2/slices/banner/Banner';
import Card from 'components/v2/atomic/card/Card';
import Button from 'components/v2/atomic/button/Button';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import Icon from 'components/v2/atomic/icon/Icon';
import { BaseStyles } from 'theme-ui';
import { useRouter } from 'next/router';
import FormTextInput from 'components/v2/atomic/textInput/FormTextInput';
import CarouselCard from 'components/v2/slices/carouselCard/CarouselCard';
import { useMaintenanceFlag } from 'features/maintenanceFlags/useMaintenanceFlag';
import Image from 'next/image';
import SliceFactory from 'components/v2/contentful/sliceFactory/SliceFactory';
import { CONTAINER_MAX_WIDTH } from 'styles/theme';
import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { SystemMaintenanceCard } from './SystemMaintenanceCard';
import styles from './LoginContainer.module.scss';
import { useEmployerQuarterly } from './EmployerQuarterly';
import MFACards from './MFA/MFACards';
import useInternalLogin, { startInternalLogin } from './useInternalLogin';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

interface LoginInfo {
  username: string;
  password: string;
}

const getCardPadding = (breakpoint: ReturnType<typeof useBreakpoint>) => {
  if (breakpoint.isLessThanSmallMin) {
    return '1.5rem';
  }
  if (breakpoint.isLessThanLargeMin) {
    return '2rem';
  }
  return '0';
};

const LoginContainer = () => {
  const { t } = useTranslation('authentication');
  const dispatch = useDispatch();
  const NEXT_PUBLIC_LEGACY_URL = env('NEXT_PUBLIC_LEGACY_URL') as string;
  const NEXT_PUBLIC_LOGIN_SCHEDULED_MAINTENANCE_CARD_ID = env(
    'NEXT_PUBLIC_LOGIN_SCHEDULED_MAINTENANCE_CARD_ID'
  ) as string;
  const NEXT_PUBLIC_NEWS_CAROUSEL_CARD_ID = env(
    'NEXT_PUBLIC_NEWS_CAROUSEL_CARD_ID'
  ) as string;

  const { logoutNotification, isAuthenticated } = useSelector(
    (state: RootState) => state.userSession
  );
  const { featureFlags } = useSelector(featureFlagSelector);

  const isLoginInMaintenanceMode = useMaintenanceFlag('login');
  const breakpoint = useBreakpoint();

  const [isSupportedBrowser, isDeprecationWarningBrowsers] = useBrowserInfo();
  const [showRedirectPopup, toggleShowRedirectPopup] = useModal();
  const [showPassword, setShowPassword] = useState(false);
  const Router = useRouter();
  const { slice } = useEmployerQuarterly();
  const [recentlyAuthenticated, setRecentlyAuthenticated] = useState(false);
  const prevIsAuthenticated = useRef(isAuthenticated);

  // initialize internal login hook to react to Ping internal SSO login redirect query params
  const { isInternalLogin } = useInternalLogin();

  const methods = useForm<LoginInfo>({
    resolver: yupResolver(
      yup.object({
        username: yup
          .string()
          .required(t('LOGIN_FIELD_USERNAME_ERROR_REQUIRED')),
        password: yup
          .string()
          .required(t('LOGIN_FIELD_PASSWORD_ERROR_REQUIRED'))
      })
    ),
    defaultValues: {
      username: '',
      password: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const onSubmit = (data: any) => {
    dispatch(loginAction(data));
  };

  enum LoginCardType {
    DEFAULT = 'default',
    INTERNAL = 'internal',
    MAINTENANCE = 'maintenance',
    ALREADY_LOGGED_IN = 'alreadyLoggedIn'
  }

  /**
   * Used to delay showing the already logged in card when the user initially logs in
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (prevIsAuthenticated.current === false && isAuthenticated) {
      setRecentlyAuthenticated(true);
      timer = setTimeout(() => {
        setRecentlyAuthenticated(false);
      }, 5000);
    }
    prevIsAuthenticated.current = isAuthenticated;
    return () => clearTimeout(timer);
  }, [isAuthenticated]);

  const getLoginCardType = (): LoginCardType => {
    if (isInternalLogin) {
      return LoginCardType.INTERNAL;
    }

    if (isLoginInMaintenanceMode) {
      return LoginCardType.MAINTENANCE;
    }

    if (
      isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER) &&
      isAuthenticated &&
      !recentlyAuthenticated
    ) {
      return LoginCardType.ALREADY_LOGGED_IN;
    }

    return LoginCardType.DEFAULT;
  };

  useEffect(() => {
    if (!isSupportedBrowser) {
      // if browser not supported, push to a brand new 'browser compatibility' page
      window.location.href = '/unsupported/unsupported-browser.html';
    }
  }, [isSupportedBrowser]);

  useEffect(() => {
    dispatch(setPasswordExpiredFlagAction(false));
    // Kept to maintain onMount behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginForm = () => (
    <div className={styles.loginForm}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h1
            sx={{
              color: isEnabled(featureFlags, currentFlags.STATIC_CONTENT)
                ? 'brandNavy100'
                : undefined
            }}
          >
            {getLoginCardType() === LoginCardType.MAINTENANCE &&
              t('LOGIN_MAINTENANCE_HEADER')}
            {getLoginCardType() === LoginCardType.ALREADY_LOGGED_IN &&
              t('LOGIN_HEADER_ALREADY_LOGGED_IN')}
            {getLoginCardType() === LoginCardType.DEFAULT && t('LOGIN_HEADER')}
          </h1>

          {!isDeprecationWarningBrowsers && (
            <Notification messages={[t('BROWSER_DEPRECATED_MSG')]} scrollTo />
          )}
          {logoutNotification === 'success' && (
            <Notification
              type="success"
              messages={[t('LOGOUT_SUCCESS_NOTIFICATION')]}
              noMargin
              marginBottom
              scrollTo
            />
          )}
          {logoutNotification === 'expired' && (
            <Notification
              type="warning"
              messages={[t('LOGOUT_SESSION_TIMEOUT_NOTIFICATION')]}
              noMargin
              marginBottom
              scrollTo
            />
          )}

          <CommonNotification />

          {getLoginCardType() === LoginCardType.DEFAULT && (
            <>
              <FormTextInput
                name="username"
                label={t('LOGIN_FIELD_USERNAME')}
                type="text"
                placeholder={t('LOGIN_FIELD_USERNAME_PLACEHOLDER')}
                id="username-field"
                data-testid="username-field"
                fill
              />
              <FormTextInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={t('LOGIN_FIELD_PASSWORD')}
                placeholder={t('LOGIN_FIELD_PASSWORD_PLACEHOLDER')}
                id="password-field"
                data-testid="password-field"
                fill
                iconRight={
                  showPassword ? IconNamesSmall.SHOW : IconNamesSmall.HIDE
                }
                onIconRightClick={() => setShowPassword(!showPassword)}
                labelInfo={
                  <Link href="/reset-password">{t('RESET_PASSWORD_LINK')}</Link>
                }
                rightAlignLabelInfo
              />
              <Button type="submit" id="login-button">
                {t('LOGIN_BUTTON')}
              </Button>
              {/**
               * Internal admin user login button displayed only in local environment
               */}
              {env('NEXT_PUBLIC_APP_ENV') === 'local' && (
                <div className={styles.localInternalLoginButton}>
                  <Button
                    onClick={() => startInternalLogin()}
                    id="internal-login-button"
                  >
                    Log In as Internal User (Local Development Only)
                  </Button>
                </div>
              )}
            </>
          )}

          {getLoginCardType() === LoginCardType.ALREADY_LOGGED_IN && (
            <>
              <Image
                src="/images/penguin-glasses-in-circle.svg"
                alt="already logged in"
                width={180}
                height={180}
                sx={{
                  width: 'unset',
                  marginBottom: '2rem'
                }}
              />
              <p>
                <span sx={{ fontWeight: 'semibold' }}>
                  {t('LOGIN_INFO_ALREADY_LOGGED_IN_1')}
                </span>
                {t('LOGIN_INFO_ALREADY_LOGGED_IN_2')}
              </p>
              <LinkButton
                id="go-to-home-button"
                sx={{ marginTop: '2rem' }}
                href="/home"
                target="_blank"
              >
                {t('LOGIN_GO_TO_EACCESS_ACCOUNT_BUTTON')}
              </LinkButton>
              <Button
                onClick={() => dispatch(logoutAction())}
                id="go-to-home-button"
                sx={{ marginY: '1rem' }}
                variant="outline"
              >
                {t('SIGN_OUT_BUTTON')}
              </Button>
            </>
          )}

          {getLoginCardType() === LoginCardType.MAINTENANCE && (
            <>
              <Image
                src="/images/login_maintenance.png"
                alt="maintenance"
                width={233}
                height={159}
                sx={{
                  width: 'unset',
                  marginBottom: '2rem'
                }}
              />
              <p>{t('LOGIN_MAINTENANCE_MESSAGE')}</p>
            </>
          )}

          {getLoginCardType() === LoginCardType.INTERNAL && (
            <>
              <div className={styles.loginInfo}>
                <div>{t('INTERNAL_LOGIN_DESCRIPTION')}</div>
              </div>
              <div className={styles.internalLoginButton}>
                <Button
                  onClick={() => startInternalLogin()}
                  id="internal-login-button"
                >
                  {t('INTERNAL_LOGIN_BUTTON_TEXT')}
                </Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>

      {getLoginCardType() === LoginCardType.DEFAULT && (
        <div className={styles.loginInfo}>
          <div>{t('LOGIN_INFO_1')}</div>

          <div className={styles.loginInfoReset}>
            <div className="semiBold">{t('LOGIN_INFO_2')}</div>
          </div>

          {!isEnabled(featureFlags, currentFlags.STATIC_CONTENT) && (
            <div className={styles.loginLinks}>
              <Link href="/security">{t('LOGIN_INFO_SECURITY_LINK')}</Link> |{' '}
              <a
                href={EXTERNAL_URLS.OMERS_PRIVACY}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('LOGIN_INFO_PRIVACY_LINK')}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      {showRedirectPopup && (
        <Modal
          titleText=""
          onCancel={toggleShowRedirectPopup}
          visible={showRedirectPopup}
          width={600}
          isOk={false}
          isCancel={false}
        >
          <div className={styles.modal}>
            <ReactSVG src="/images/helper.svg" />
            <h2>{t('LOGIN_MODAL_TITLE')}</h2>
            <p>{t('LOGIN_MODAL_INFO_1')}</p>
            <p>
              {t('LOGIN_MODAL_INFO_2')}
              <div className="semiBold"> {t('LOGIN_MODAL_INFO_3')} </div>
              {t('LOGIN_MODAL_INFO_4')}
            </p>
            <p>{t('LOGIN_MODAL_INFO_5')}</p>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${NEXT_PUBLIC_LEGACY_URL}`}
            >
              <Button onClick={toggleShowRedirectPopup} id="login-modal-button">
                {t('LOGIN_MODAL_BUTTON')}
              </Button>
            </a>
          </div>
        </Modal>
      )}
      <div className={styles.login}>
        {!isEnabled(featureFlags, currentFlags.STATIC_CONTENT) && (
          <SplashWrapper>{loginForm()}</SplashWrapper>
        )}
        {isEnabled(featureFlags, currentFlags.STATIC_CONTENT) && (
          <div
            sx={{
              'div#home-banner >div': {
                paddingLeft: getCardPadding(breakpoint),
                minHeight: breakpoint.isLessThanSmallMin
                  ? '21.875rem'
                  : '46.25rem'
              }
            }}
          >
            <Banner
              id="home-banner"
              bannerHeight="fit-content"
              backgroundImageUrl={
                breakpoint.isLessThanSmallMin
                  ? '/images/backgrounds/public-login-bg-mobile.png'
                  : '/images/backgrounds/public-login-bg.jpg'
              }
              captionText={
                breakpoint.isBetweenMediumAndXLarge || breakpoint.isXLarge
                  ? t('LOGIN_BANNER_FOOTER_TEXT')
                  : ''
              }
              graphicOverlayWidth="100%"
              captionColour="white"
              sx={{
                zIndex: 1
              }}
            >
              {!breakpoint.isLessThanSmallMin && (
                <div
                  sx={{
                    display: 'flex',
                    maxWidth: !breakpoint.isLessThanLargeMin
                      ? '93.75rem'
                      : undefined,
                    margin: '0 auto',
                    gap: '2rem'
                  }}
                >
                  {isEnabled(featureFlags, currentFlags.MFA) ? (
                    <MFACards isInternalLogin={isInternalLogin} />
                  ) : (
                    <Card
                      id="login-card"
                      accent
                      accentColor="brandOrangeWeb"
                      sxOverride={{
                        width: breakpoint.isLessThanSmallMin ? '100%' : '40rem',
                        marginBottom: breakpoint.isBetweenMediumAndLarge
                          ? '2rem'
                          : '3rem'
                      }}
                    >
                      <BaseStyles>{loginForm()}</BaseStyles>
                    </Card>
                  )}

                  <div
                    sx={{
                      display: breakpoint.isLessThanSmallMin ? 'none' : 'unset'
                    }}
                  />
                </div>
              )}
            </Banner>
          </div>
        )}
      </div>
      {isEnabled(featureFlags, currentFlags.STATIC_CONTENT) && (
        <>
          {breakpoint.isLessThanSmallMin &&
            (isEnabled(featureFlags, currentFlags.MFA) ? (
              <MFACards isInternalLogin={isInternalLogin} />
            ) : (
              <Card
                id="login-card"
                accent
                accentColor="brandOrangeWeb"
                sxOverride={{
                  margin: '1.5rem',
                  marginTop: '-6.25rem',
                  marginBottom: 0,
                  position: 'relative',
                  zIndex: 20
                }}
              >
                <BaseStyles>{loginForm()}</BaseStyles>
              </Card>
            ))}

          <div
            sx={{
              margin: '0 auto',
              marginBottom: '3rem',
              paddingX: breakpoint.isLessThanMediumMin
                ? '1.5rem'
                : breakpoint.isLessThanLargeMin
                ? '2rem'
                : 0,
              maxWidth: !breakpoint.isLessThanLargeMin ? '93.75rem' : undefined,
              marginTop: breakpoint.isLessThanSmallMin
                ? '1.5rem'
                : !breakpoint.isBetweenSmallAndLarge
                ? '2rem'
                : '3rem'
            }}
          >
            <div
              sx={{
                display: 'flex',
                flexDirection: breakpoint.isLessThanMediumMin
                  ? 'column'
                  : 'row',
                gap: '2rem',
                marginBottom: '2rem',
                height: 'fit-content'
              }}
            >
              <SystemMaintenanceCard
                entryId={NEXT_PUBLIC_LOGIN_SCHEDULED_MAINTENANCE_CARD_ID}
              />
              <CarouselCard entryId={NEXT_PUBLIC_NEWS_CAROUSEL_CARD_ID} />
            </div>
            <div
              sx={{
                display: 'flex',
                flexDirection: breakpoint.isLessThanMediumMin
                  ? 'column'
                  : 'row',
                gap: '2rem',
                marginBottom: '2rem'
              }}
            >
              <Card
                id="Employer-Administration-Manual"
                accent
                accentColor="brandNavy100"
                sxOverride={{
                  flex: 1
                }}
              >
                <BaseStyles>
                  <h2>{t('EMPLOYER_ADMINISTRAION_MANUAL_TITLE')}</h2>
                  <p>{t('EMPLOYER_ADMINISTRAION_MANUAL_DESC')}</p>
                  <Button
                    id="employer-administration-manual-button"
                    color="blue"
                    variant="outline"
                    data-testid="employer-administration-manual-button"
                    onClick={() => {
                      window.open(EXTERNAL_URLS.OMERS_EAM_HOMEPAGE, '_ blank');
                    }}
                    isIconButton
                    sx={{
                      width: ['fit-content', '100%', null]
                    }}
                  >
                    {t('LAUNCH_MANUAL')}
                    <Icon icon={IconNamesSmall.OPEN_IN_NEW} />
                  </Button>
                </BaseStyles>
              </Card>
              <Card
                id="training-card"
                accent
                accentColor="brandNavy100"
                sxOverride={{
                  flex: 1
                }}
              >
                <div
                  sx={{
                    display: 'flex',
                    flexDirection: breakpoint.isLessThanSmallMin
                      ? 'column-reverse'
                      : 'row',
                    gap: '2rem'
                  }}
                >
                  <BaseStyles>
                    <h2>{t('TRAINING_CARD_TITLE')}</h2>
                    <p>{t('TRAINING_CARD_DESC')}</p>
                    <Button
                      id="training-card-button"
                      color="blue"
                      variant="outline"
                      data-testid="training-card-button"
                      onClick={() => {
                        Router.push('/content/get-training');
                      }}
                      sx={{
                        width: ['fit-content', '100%', null]
                      }}
                    >
                      {t('GET_TRAINING')}
                    </Button>
                  </BaseStyles>
                  <img
                    data-testid="training-logo"
                    src="/images/backgrounds/training-login.svg"
                    alt="training-logo"
                    sx={{
                      width: breakpoint.isLessThanSmallMin
                        ? '11rem'
                        : '12.5rem',
                      alignSelf: breakpoint.isLessThanSmallMin
                        ? 'center'
                        : 'flex-end'
                    }}
                  />
                </div>
              </Card>
            </div>

            {slice && (
              <BaseStyles>
                <div
                  sx={{
                    maxWidth: CONTAINER_MAX_WIDTH,
                    margin: '0 auto',
                    backgroundColor: 'white'
                  }}
                >
                  <SliceFactory slice={slice} />
                </div>
              </BaseStyles>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LoginContainer;
