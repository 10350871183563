import Head from 'next/head';
import { NextPage } from 'next';
import { useTranslation } from 'react-i18next';

import LoginContainer from 'containers/auth/LoginContainer/LoginContainer';

const Login: NextPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('PLATFORM_TITLE')}</title>
        <link rel="icon" href="/favicon/favicon.ico" />
      </Head>
      <LoginContainer />
    </>
  );
};

export default Login;
