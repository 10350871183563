import { useMaintenanceFlag } from 'features/maintenanceFlags/useMaintenanceFlag';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { useMount } from 'react-use';
import useLocalStorageState from 'use-local-storage-state';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import MFAMaintenanceCard from './MFAMaintenanceCard';
import MFALoginFormCard from './MFALoginFormCard';
import MFAAccountLockedCard from './MFAAccountLockedCard';
import MFAAlreadyLoggedInCard from './MFAAlreadyLoggedInCard';
import MFACodeVerificationFormCard from './MFACodeVerificationFormCard';
import MFAInternalLogInCard from './MFAInternalLogInCard';
import MFAAccountTemporarilyBlockedCard, {
  isTTLExpired
} from './MFAAccountTemporarilyBlockedCard';

export enum MFACardTypes {
  LOGIN_FORM_CARD = 'MFALoginFormCard',
  PASS_CODE_VERIFICATION_CARD = 'MFACodeVerificationFormCard',
  ACCOUNT_LOCKED_CARD = 'MFAccountLockedCard',
  ACCOUNT_TEMP_BLOCKED_CARD = 'MFAAccountTemporarilyBlockedCard'
}

export interface LoginFormsInfo {
  verificationSid: string | null;
  email: string | null;
  username: string | null;
  deviceProfile: {
    platform: string | null;
    browser: string | null;
    timezone: string | null;
    cpu: string | null;
    visitorId: string | null;
  };
}

const LoginForms = ({
  currentCard,
  setCurrentCard
}: {
  currentCard: MFACardTypes;
  setCurrentCard: (card: MFACardTypes) => void;
}) => {
  const [mfaCardsSharedData, setMfaCardsSharedData] = useState<LoginFormsInfo>({
    verificationSid: null,
    email: null,
    username: null,
    deviceProfile: {
      platform: null,
      browser: null,
      timezone: null,
      cpu: null,
      visitorId: null
    }
  });

  // Note: Device profile taken by FingerPrintJS is only 30-50% accurate.
  useMount(async () => {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const fpResult = await fp.get();

    if (fpResult) {
      // The property value exists but typing from FingerPrintJS has a bug
      setMfaCardsSharedData({
        ...mfaCardsSharedData,
        deviceProfile: {
          // @ts-ignore
          cpu: fpResult.components.osCpu.value ?? null,
          // @ts-ignore
          timezone: fpResult.components.timezone.value ?? null,
          // @ts-ignore
          platform: fpResult.components.platform.value ?? null,
          // @ts-ignore
          browser: fpResult.components.vendorFlavors.value[0] ?? null,
          visitorId: fpResult.visitorId ?? null
        }
      });
    }
  });

  if (currentCard === MFACardTypes.PASS_CODE_VERIFICATION_CARD) {
    return (
      <MFACodeVerificationFormCard
        setCurrentCard={setCurrentCard}
        mfaCardsSharedData={mfaCardsSharedData}
        setMfaCardsSharedData={setMfaCardsSharedData}
      />
    );
  }

  return (
    <MFALoginFormCard
      setCurrentCard={setCurrentCard}
      mfaCardsSharedData={mfaCardsSharedData}
      setMfaCardsSharedData={setMfaCardsSharedData}
    />
  );
};

export enum AccountStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked'
}
export interface AccountStatusState {
  status: AccountStatus;
  TTL: number | null;
}

export const ACCOUNT_STATUS = 'accountStatus';

const MFACards = ({ isInternalLogin }: { isInternalLogin: boolean }) => {
  const [currentCard, setCurrentCard] = useState<MFACardTypes>(
    MFACardTypes.LOGIN_FORM_CARD
  );
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );

  const { isAuthenticated } = useSelector(
    (state: RootState) => state.userSession
  );
  const isMFAInMaintenanceMode = useMaintenanceFlag('mfa');
  const [recentlyAuthenticated, setRecentlyAuthenticated] = useState(false);
  const prevIsAuthenticated = useRef(isAuthenticated);

  const [accountStatus, _] = useLocalStorageState<AccountStatusState>(
    ACCOUNT_STATUS,
    {
      defaultValue: {
        status: AccountStatus.ACTIVE,
        TTL: null
      }
    }
  );

  useMount(() => {
    // Checks the local storage and shows the account temporary blocked card if applicable
    if (!isTTLExpired(accountStatus.TTL)) {
      setCurrentCard(MFACardTypes.ACCOUNT_TEMP_BLOCKED_CARD);
    }
  });

  /**
   * Used to delay showing the already logged in card when the user initially logs in
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (prevIsAuthenticated.current === false && isAuthenticated) {
      setRecentlyAuthenticated(true);
      timer = setTimeout(() => {
        setRecentlyAuthenticated(false);
      }, 5000);
    }
    prevIsAuthenticated.current = isAuthenticated;
    return () => clearTimeout(timer);
  }, [isAuthenticated]);

  const alreadyLoggedIn =
    isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER) &&
    isAuthenticated &&
    !recentlyAuthenticated;

  if (isMFAInMaintenanceMode) {
    return <MFAMaintenanceCard />;
  }

  if (isInternalLogin) {
    return <MFAInternalLogInCard />;
  }

  if (alreadyLoggedIn) {
    return <MFAAlreadyLoggedInCard />;
  }

  if (currentCard === MFACardTypes.ACCOUNT_LOCKED_CARD) {
    return <MFAAccountLockedCard setCurrentCard={setCurrentCard} />;
  }

  if (currentCard === MFACardTypes.ACCOUNT_TEMP_BLOCKED_CARD) {
    return <MFAAccountTemporarilyBlockedCard setCurrentCard={setCurrentCard} />;
  }

  return (
    <LoginForms setCurrentCard={setCurrentCard} currentCard={currentCard} />
  );
};

export default MFACards;
