import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';

const MFAMaintenanceCard = () => {
  const { t } = useTranslation('authentication');

  return (
    <MFACard id="mfa-maintenance-card" title={t('LOGIN_MAINTENANCE_HEADER')}>
      <Image
        src="/images/login_maintenance.png"
        alt=""
        width={233}
        height={159}
        sx={{
          width: 'unset',
          marginBottom: '2rem'
        }}
      />
      <p>{t('LOGIN_MAINTENANCE_MESSAGE')}</p>
    </MFACard>
  );
};

export default MFAMaintenanceCard;
