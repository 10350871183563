import { useEffect, useState } from 'react';
import Bowser from 'bowser';
import {
  SUPPORTED_BROWSERS,
  COMPATIBLE_BROWSERS,
  BOT_SUPPORTED_BROWSERS
} from '../constants';

const useBrowserInfo = () => {
  const [userBrowser, setUserBrowser] = useState([]);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(true);
  const [
    isDeprecationWarningBrowsers,
    setIsDeprecationWarningBrowsers
  ] = useState(true);

  useEffect(() => {
    const browser: Bowser.Parser.Parser = Bowser.getParser(
      window.navigator.userAgent
    );
    const isSupported = !!(
      browser.satisfies(SUPPORTED_BROWSERS) ||
      browser.satisfies(BOT_SUPPORTED_BROWSERS)
    );
    const isDeprecated = !!browser.satisfies(COMPATIBLE_BROWSERS);
    const browserDetails: any = browser.getBrowser();
    setUserBrowser(browserDetails);
    setIsSupportedBrowser(isSupported);
    setIsDeprecationWarningBrowsers(isDeprecated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isSupportedBrowser, isDeprecationWarningBrowsers, userBrowser];
};

export default useBrowserInfo;
