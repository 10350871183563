import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import MFACardNavButtons from 'containers/auth/LoginContainer/MFA/components/MFACustomButtons';
import {
  AccountStatusState,
  ACCOUNT_STATUS,
  MFACardTypes
} from 'containers/auth/LoginContainer/MFA/MFACards';
import useLocalStorageState from 'use-local-storage-state';
import { isAfter } from 'date-fns';
import { useInterval } from 'react-use';

export const isTTLExpired = (TTL: number | null | undefined) => {
  if (!TTL) {
    return true;
  }

  return isAfter(Date.now(), TTL);
};
const MFAAccountTemporarilyBlockedCard = ({
  setCurrentCard
}: {
  setCurrentCard: (state: MFACardTypes) => void;
}) => {
  const { t } = useTranslation('authentication');

  const [accountStatus, _] = useLocalStorageState<AccountStatusState>(
    ACCOUNT_STATUS
  );

  useInterval(() => {
    if (isTTLExpired(accountStatus?.TTL)) {
      setCurrentCard(MFACardTypes.LOGIN_FORM_CARD);
    }
  }, 1000 * 60);

  return (
    <MFACard id="mfa-account-blocked" title={t('MFA_ACCOUNT_BLOCKED')}>
      <div sx={{ display: 'flex', alignItems: 'start', gap: '1.5rem' }}>
        <Image
          src="/images/v2/smallIcons/blocking-hand.svg"
          alt=""
          width={92}
          height={92}
          sx={{
            width: 'unset',
            marginBottom: '2rem'
          }}
        />
        <p sx={{ mb: '0' }}>{t('MFA_LOGIN_TEMPORARILY_LOCKED')}</p>
      </div>

      <MFACardNavButtons
        setCurrentCard={setCurrentCard}
        showBackToLogIn={false}
      />
    </MFACard>
  );
};

export default MFAAccountTemporarilyBlockedCard;
