import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormTextInput from 'components/v2/atomic/textInput/FormTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPasswordExpiredFlagAction,
  setUsernameForResetExpiredPasswordAction,
  userSessionActionTypes
} from 'containers/auth/userSessionActions';
import { shouldShowEmployer } from 'containers/auth/userSessionSaga';
import { RootState } from 'types';
import { PingCredentialErrors } from 'utils/ping/types';
import { useRouter } from 'next/router';
import clientSide from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import { MFASubmitButton } from 'containers/auth/LoginContainer/MFA/components/MFACustomButtons';
import Button from 'components/v2/atomic/button/Button';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { AuthLoginResponse } from 'pages/api/auth/login.api';
import useLocalStorageState from 'use-local-storage-state';
import CalloutNotification from 'components/v2/atomic/calloutNotification/CalloutNotification';
import { useMount } from 'react-use';
import { clearNotifications } from 'containers/notifications/notificationsActions';
import {
  AccountStatus,
  AccountStatusState,
  ACCOUNT_STATUS,
  MFACardTypes,
  LoginFormsInfo
} from './MFACards';
import { loginFormSchema } from './schemas';
import {
  getNotificationMessage,
  handleLoginSuccess
} from './utils/client-utils';
import { MFAErrorCodes, TwilioDonnaError } from './utils/types';
import { startInternalLogin } from '../useInternalLogin';

const MFALoginFormCard = ({
  setCurrentCard,
  setMfaCardsSharedData,
  mfaCardsSharedData
}: {
  setCurrentCard: (state: MFACardTypes) => void;
  setMfaCardsSharedData: (state: LoginFormsInfo) => void;
  mfaCardsSharedData: LoginFormsInfo;
}) => {
  const { t } = useTranslation('authentication');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<
    MFAErrorCodes | PingCredentialErrors | null
  >(null);
  const router = useRouter();
  const schema = loginFormSchema(t);
  const dispatch = useDispatch();
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const [_, setAccountStatus] = useLocalStorageState<AccountStatusState>(
    ACCOUNT_STATUS
  );

  type LoginFormType = z.infer<typeof schema>;

  const methods = useForm<LoginFormType>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      password: ''
    }
  });

  useMount(() => {
    dispatch(clearNotifications());
  });

  const onValid = async (data: LoginFormType) => {
    setErrorCode(null);
    try {
      setLoading(true);

      const loginRes = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...data,
          deviceProfile: mfaCardsSharedData.deviceProfile
        })
      });

      const loginResJson: AuthLoginResponse = await loginRes.json();
      setLoading(false);

      if (loginResJson.result.data) {
        // MFA; Twilio code is returned from the backend
        if (loginResJson.result.data.verificationSid) {
          setCurrentCard(MFACardTypes.PASS_CODE_VERIFICATION_CARD);
          setMfaCardsSharedData({
            ...mfaCardsSharedData,
            email: loginResJson.result.data.userProfile.email,
            verificationSid: loginResJson.result.data.verificationSid,
            username: loginResJson.result.data.userProfile.username
          });
          return;
        }
        // No MFA; MFA exempted because users have cache in Redis
        if (loginResJson.result.data.accessToken) {
          await handleLoginSuccess({
            userProfile: loginResJson.result.data.userProfile,
            accessToken: loginResJson.result.data.accessToken,
            shouldShowEmployer: shouldShowEmployer(
              featureFlags,
              loginResJson.result.data.userProfile
            ),
            dispatch
          });
          return;
        }
        return;
      }

      dispatch({
        type: userSessionActionTypes.LOGIN_FAILED
      });

      if (
        loginResJson.result.errors[0].code ===
        PingCredentialErrors.ACCOUNT_LOCKED
      ) {
        setCurrentCard(MFACardTypes.ACCOUNT_LOCKED_CARD);
        return;
      }
      if (
        loginResJson.result.errors[0].code ===
        PingCredentialErrors.MUST_CHANGE_PASSWORD
      ) {
        dispatch(setPasswordExpiredFlagAction(true));
        dispatch(setUsernameForResetExpiredPasswordAction(data.username));
        router.push('/update-password');
        return;
      }

      // If users have sent more than 5 verification codes in 10 minutes
      // Or if users have tried a wrong pass code more than 5 times,
      // Twilio throws the MAX_SEND_ATTEMPTS_REACHED error
      if (
        loginResJson.result.errors[0].code ===
        MFAErrorCodes.MAX_SEND_ATTEMPTS_REACHED
      ) {
        // If users have cleared their local storage,
        // update the status and TTL that the backend returns to the local storage
        setCurrentCard(MFACardTypes.ACCOUNT_TEMP_BLOCKED_CARD);
        setAccountStatus({
          status: AccountStatus.BLOCKED,
          TTL: (loginResJson.result.errors[0] as TwilioDonnaError)
            .verificationTTL
        });
      }

      setErrorCode(
        (loginResJson.result.errors[0].code as
          | PingCredentialErrors
          | MFAErrorCodes) ?? MFAErrorCodes.GENERIC_ERROR
      );
      return;
    } catch (e) {
      clientSide.error(
        'Failed to call /auth/api/login during MFA; unknown error',
        toLoggerError(e)
      );
      dispatch({
        type: userSessionActionTypes.LOGIN_FAILED
      });
      setErrorCode(MFAErrorCodes.GENERIC_ERROR);
    }
  };

  return (
    <MFACard id="mfa-form-card" title={t('LOGIN_HEADER')}>
      {errorCode && (
        <CalloutNotification
          id="mfa-form-error"
          intent="danger"
          sx={{ mb: '2rem' }}
        >
          <p>{getNotificationMessage(errorCode)}</p>
        </CalloutNotification>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onValid)}>
          <div sx={{ '>div': { mb: '2rem' } }}>
            <FormTextInput
              name="username"
              id="username"
              label={t('LOGIN_FIELD_USERNAME')}
              placeholder={t('LOGIN_FIELD_USERNAME_PLACEHOLDER')}
              fill
              error={
                (methods.formState.errors.username?.message as string) ?? ''
              }
            />
          </div>
          <div sx={{ '>div': { mb: '2rem' }, position: 'relative' }}>
            <Link
              href="/reset-password"
              sx={{ position: 'absolute', right: '0' }}
            >
              {t('RESET_PASSWORD_LINK')}
            </Link>
            <FormTextInput
              id="password"
              name="password"
              label={t('LOGIN_FIELD_PASSWORD')}
              placeholder={t('LOGIN_FIELD_PASSWORD_PLACEHOLDER')}
              type={showPassword ? 'text' : 'password'}
              iconRight={
                showPassword ? IconNamesSmall.SHOW : IconNamesSmall.HIDE
              }
              onIconRightClick={() => setShowPassword(!showPassword)}
              rightAlignLabelInfo
              fill
              error={
                (methods.formState.errors.password?.message as string) ?? ''
              }
            />
          </div>
          <MFASubmitButton id="mfa-login-button" loading={loading}>
            {loading ? t('MFA_LOGGING_IN') : t('LOGIN_BUTTON')}
          </MFASubmitButton>

          {/**
           * Internal admin user login button displayed only in local environment
           */}
          {env('NEXT_PUBLIC_APP_ENV') === 'local' && (
            <div>
              <Button
                onClick={() => startInternalLogin()}
                id="internal-login-button"
                fill
                sxOverride={{ mt: '1.5rem' }}
              >
                Log In as Internal User (Local Development Only)
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
      <span
        sx={{
          mt: '2rem',
          mb: '1rem',
          fontSize: '0.875rem',
          lineHeight: '1.3125rem',
          display: 'block'
        }}
      >
        {t('LOGIN_INFO_1')}
      </span>

      <p sx={{ mb: '0' }}>
        <span
          sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: '1.5rem' }}
        >
          {t('LOGIN_INFO_2')}
        </span>
      </p>
    </MFACard>
  );
};

export default MFALoginFormCard;
