import CalloutNotification from 'components/v2/atomic/calloutNotification/CalloutNotification';
import Card from 'components/v2/atomic/card/Card';
import CommonNotification from 'containers/notifications/components/commonNotification/CommonNotification';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseStyles } from 'theme-ui';
import { RootState } from 'types';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import useBrowserInfo from 'utils/hooks/useBrowserInfo';

export default function MFACard({
  id,
  title,
  children
}: Readonly<{
  id: string;
  title?: string;
  children: React.ReactNode;
}>) {
  const { t } = useTranslation('authentication');
  const breakpoint = useBreakpoint();
  const [_, isDeprecationWarningBrowsers] = useBrowserInfo();
  const { logoutNotification } = useSelector(
    (state: RootState) => state.userSession
  );

  return (
    <Card
      id={id}
      accent
      accentColor="brandOrangeWeb"
      sxOverride={{
        width: breakpoint.isLessThanSmallMin ? undefined : '40rem',
        marginX: breakpoint.isLessThanSmallMin ? '1.5rem' : undefined,
        marginTop: breakpoint.isLessThanSmallMin ? '-6.25rem' : undefined,
        marginBottom: '1.5rem',
        position: breakpoint.isLessThanSmallMin ? 'relative' : 'static',
        zIndex: breakpoint.isLessThanSmallMin ? 20 : undefined,
        padding: '1rem',
        border: 'none'
      }}
    >
      <BaseStyles>
        {title ? (
          <div sx={{ '>h1': { mb: '2rem', mt: 0 } }}>
            <h1>{title}</h1>
          </div>
        ) : null}
        {!isDeprecationWarningBrowsers && (
          <CalloutNotification
            id="browser-deprecated"
            intent="warning"
            sx={{ mb: '2rem' }}
          >
            {t('BROWSER_DEPRECATED_MSG')}
          </CalloutNotification>
        )}
        {logoutNotification === 'success' && (
          <CalloutNotification
            intent="success"
            id="logout-success-notification"
            sx={{ mb: '2rem' }}
          >
            {t('LOGOUT_SUCCESS_NOTIFICATION')}
          </CalloutNotification>
        )}
        {logoutNotification === 'expired' && (
          <CalloutNotification
            intent="warning"
            id="logout-timeout-notification"
            sx={{ mb: '2rem' }}
          >
            {t('LOGOUT_SESSION_TIMEOUT_NOTIFICATION')}
          </CalloutNotification>
        )}
        <CommonNotification />
        {children}
      </BaseStyles>
    </Card>
  );
}
