import Button from 'components/v2/atomic/button/Button';
import { useTranslation } from 'react-i18next';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import { startInternalLogin } from '../useInternalLogin';

export default function MFAInternalLogInCard() {
  const { t } = useTranslation('authentication');

  return (
    <MFACard id="internal-login">
      <div>{t('INTERNAL_LOGIN_DESCRIPTION')}</div>
      <div sx={{ mt: '2.5rem' }}>
        <Button
          onClick={() => startInternalLogin()}
          id="internal-login-button"
          fill
        >
          {t('INTERNAL_LOGIN_BUTTON_TEXT')}
        </Button>
      </div>
    </MFACard>
  );
}
