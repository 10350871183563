import Button from 'components/v2/atomic/button/Button';
import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import { logoutAction } from 'containers/auth/userSessionActions';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';

export default function MFAAlreadyLoggedInCard() {
  const { t } = useTranslation('authentication');
  const dispatch = useDispatch();

  return (
    <MFACard
      id="already-logged-in-card"
      title={t('LOGIN_HEADER_ALREADY_LOGGED_IN')}
    >
      <Image
        src="/images/penguin-glasses-in-circle.svg"
        alt="already logged in"
        width={180}
        height={180}
        sx={{
          width: 'unset',
          marginBottom: '2rem'
        }}
      />
      <p>
        <span sx={{ fontWeight: 'semibold' }}>
          {t('LOGIN_INFO_ALREADY_LOGGED_IN_1')}
        </span>
        {t('LOGIN_INFO_ALREADY_LOGGED_IN_2')}
      </p>
      <LinkButton
        id="go-to-home-button"
        sx={{ marginTop: '2rem' }}
        href="/home"
        target="_blank"
      >
        {t('LOGIN_GO_TO_EACCESS_ACCOUNT_BUTTON')}
      </LinkButton>
      <Button
        onClick={() => dispatch(logoutAction())}
        id="go-to-home-button"
        sx={{ marginY: '1rem' }}
        variant="outline"
        fill
      >
        {t('SIGN_OUT_BUTTON')}
      </Button>
    </MFACard>
  );
}
