import { memo } from 'react';
import { get, isEmpty } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { string } from 'yup';

import TextInput, { TextInputProps } from './TextInput';

export const formTextYupSchema = string().nullable();

interface FormTextInputProps extends TextInputProps {
  name: string;
  isClearable?: boolean;
}

const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  isClearable = false,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues
  } = useFormContext();

  const { ref } = register(name);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props?.defaultValue}
      render={({
        field: {
          name: maskName,
          onChange: maskOnChange,
          onBlur: maskOnBlur,
          value: maskValue
        }
      }) => (
        <TextInput
          {...props}
          name={maskName}
          onChange={e => {
            props?.onChange?.(e);
            maskOnChange(e);
          }}
          onBlur={e => {
            props?.onBlur?.(e);
            maskOnBlur();
          }}
          value={maskValue}
          ref={ref}
          onClear={
            isClearable && !isEmpty(getValues(name))
              ? () => setValue(name, '')
              : undefined
          }
          // Because Lodash get does not know the type of errors
          // Type casting its return value as string
          error={
            props.error ??
            ((get(errors, `${name}.message`) as unknown) as string)
          }
        />
      )}
    />
  );
};

export default memo(FormTextInput);
