import { datadogRum } from '@datadog/browser-rum';
import {
  loginSucceededAction,
  setShowEmailNotificationRowAction,
  setSysDateAction
} from 'containers/auth/userSessionActions';
import { selectEmployerAction } from 'containers/selectEmployers/selectEmployersActions';
import Router from 'next/router';
import { Dispatch } from 'redux';
import { getSysDate } from 'services';
import { SysDateObj } from 'types';
import { getApiErrorMessageByCode, setAccessToken } from 'utils';
import { JWTUser, PingCredentialErrors } from 'utils/ping/types';
import { MFAErrorCodes } from './types';

export function getNotificationMessage(
  code: MFAErrorCodes | PingCredentialErrors | string
) {
  switch (code) {
    case PingCredentialErrors.INVALID_CREDENTIALS:
    case PingCredentialErrors.DISABLED_USER:
    case PingCredentialErrors.ACCESS_DENIED:
    case MFAErrorCodes.VERIFICATION_NOT_FOUND:
    case MFAErrorCodes.INVALID_VERIFICATION_CODE:
      return getApiErrorMessageByCode(code);
    case MFAErrorCodes.UNKNOWN_ERROR_CODE:
    case MFAErrorCodes.GENERIC_ERROR:
      return getApiErrorMessageByCode(MFAErrorCodes.GENERIC_ERROR);
    default:
      return getApiErrorMessageByCode(MFAErrorCodes.GENERIC_ERROR);
  }
}

export function maskEmail(email: string | null) {
  if (!email) {
    return '';
  }
  const [username, _] = email.split('@');
  return email.replace(username.slice(1, -1), '*'.repeat(username.length - 2));
}

export async function handleLoginSuccess({
  userProfile,
  accessToken,
  shouldShowEmployer,
  dispatch
}: {
  userProfile: JWTUser;
  accessToken: string;
  shouldShowEmployer: boolean;
  dispatch: Dispatch;
}) {
  datadogRum.setUser({
    id: userProfile.username,
    email: userProfile.email
  });

  dispatch(loginSucceededAction({ accessToken, userProfile }));
  setAccessToken(accessToken);
  Sprig('setUserId', userProfile.username);

  // retrieve e-access system date to sync with backend date
  const sysDate: { data: { sysDate: SysDateObj } } = await getSysDate();

  if (sysDate?.data) {
    dispatch(setSysDateAction(sysDate.data));
  }

  if (userProfile.omersUser) {
    dispatch(setShowEmailNotificationRowAction(false));
  }

  if (
    shouldShowEmployer ||
    userProfile.groups.length > 1 ||
    userProfile.omersUser // internal user
  ) {
    Router.push('/select-employers');
  } else {
    if (userProfile.training) {
      Router.push('/learning-centre');
      return;
    }

    if (userProfile.groups.length === 1) {
      dispatch(
        selectEmployerAction({
          name: userProfile.groups[0].name,
          employerNumber: userProfile.groups[0].employerNumber,
          groupNumber: userProfile.groups[0].groupNumber
        })
      );
    }
  }
}
