import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notifyErrorAction } from 'containers/notifications/notificationsActions';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { PING_AUTH_PATH } from 'utils/ping/constants';
import {
  internalUserLoginAction,
  loginFailedAction
} from '../userSessionActions';

export const startInternalLogin = () => {
  const authCodeParams = new URLSearchParams({
    client_id: env('NEXT_PUBLIC_PING_AUTH_INTERNAL_CLIENT_ID') as string,
    response_type: 'code',
    redirect_uri: window.location.origin
  }).toString();

  window.location.assign(
    `${env(
      'NEXT_PUBLIC_PING_AUTH_BASE_URL'
    )}${PING_AUTH_PATH}?${authCodeParams}`
  );
};

/**
 * Hook to be used by LoginContainer to handle internal (admin) login workflow; watches for browser url query params
 * for authorization code or error response query params from Ping internal SSO login redirect
 */
const useInternalLogin = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { code, error } = router.query;

  // handle error flow from Ping internal SSO login redirect response
  useEffect(() => {
    if (error) {
      dispatch(loginFailedAction());

      dispatch(
        notifyErrorAction(
          // @todo - this shouldn't be hardcoded, but it's a direct copy from the original code
          'System error. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
        )
      );
    }
  }, [dispatch, error]);

  // handle successful internal login flow from Ping internal SSO login redirect response with authentication code
  useEffect(() => {
    if (code) {
      dispatch(
        internalUserLoginAction({
          authCode: code as string
        })
      );
    }
  }, [dispatch, code]);

  return {
    /**
     * Flag to indicate if the current login flow is an internal login flow
     * based on either A) `admin` contained within the url path or B) the
     * presence of an auth code or error query param
     */
    isInternalLogin:
      window.location.hostname.indexOf('admin') !== -1 || !!code || !!error
  };
};

export default useInternalLogin;
